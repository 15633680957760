import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

// Connects to data-controller="ratings"
export default class extends Controller {
  connect() {
    const path = window.location.pathname.match(/\/courses\/(\d*)\/curriculum/);
    if (path) {
      const courseId = path[1];
      get(`/course_ratings?course=${courseId}`, {responseKind: "turbo-stream"});
    }
  }
}
