import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="applepay"
export default class extends Controller {
  static targets = ["appleForm"];

  static values = {
    sessionId: String,
    countryCode: String,
    amount: String,
  };

  connect() {
    this.waitForMyFatoorah();
  }

  waitForMyFatoorah() {
    if (typeof myFatoorahAP !== 'undefined') {
      this.initializeApplePay();
    } else {
      window.addEventListener('load', () => {
        this.waitForMyFatoorahAfterLoad();
      });
    }
  }

  waitForMyFatoorahAfterLoad() {
    if (typeof myFatoorahAP !== 'undefined') {
      this.initializeApplePay();
    } else {
      setTimeout(() => this.waitForMyFatoorahAfterLoad(), 50);
    }
  }

  initializeApplePay() {
    const payment = (response) => {
      try {
        this.appleFormTarget.querySelector("#applepay_session_id").value = response.sessionId;
        this.appleFormTarget.requestSubmit();
      } catch (err) {
        if (typeof Rollbar !== "undefined") {
          Rollbar.critical(err);
        }
      }
    };

    var appleConfig = {
      sessionId: this.sessionIdValue,
      countryCode: this.countryCodeValue,
      currencyCode: "SAR",
      amount: this.amountValue,
      cardViewId: "apple-element",
      callback: payment,
    };

    try {
      myFatoorahAP.init(appleConfig);
    } catch (err) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.critical(err);
      }
    }
  }
}
