import {Controller} from "@hotwired/stimulus";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-css";

export default class extends Controller {
  connect() {
    this.editor = ace.edit("ace-editor");
    this.editor.setTheme("ace/theme/monokai");
    this.editor.session.setMode("ace/mode/css");
    this.editor.setValue(document.getElementById("custom_css").value); // Load initial value from hidden input
    this.editor.session.setOptions({
      useWorker: false,
      tabSize: 2,
      useSoftTabs: true,
    });
    this.editor.setOptions({
      fontFamily: "monospace",
      fontSize: "14px",
      showPrintMargin: false,
      highlightActiveLine: true,
      highlightSelectedWord: true,
    });

    this.editor.session.on("change", () => {
      document.getElementById("custom_css").value = this.editor.getValue();
    });
  }
}
