import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["requestOtp", "loginButton", "timeLeft", "timeLeftText"];
  connect() {
    if (this.hasRequestOtpTarget) {
      this.loginButtonTarget.disabled = true;
    }
  }

  timeLeftTextTargetConnected() {
    let timeleft = this.timeLeftTextTarget.dataset.interval;
    this.requestOtpTarget.disabled = true;
    this.loginButtonTarget.disabled = false;
    this.element.dataset.turbo = false;
    const downloadTimer = setInterval(() => {
      timeleft--;
      this.timeLeftTarget.innerHTML = timeleft;
      if (timeleft <= 0) {
        this.timeLeftTarget.innerHTML = "0";
        clearInterval(downloadTimer);
        this.timeLeftTextTarget.classList.add("hidden");
        this.requestOtpTarget.disabled = false;
        this.loginButtonTarget.disabled = true;
        this.element.dataset.turbo = true;
      }
    }, 1000);
  }
}
