import {Controller} from "@hotwired/stimulus";
import {debounceSearch} from "../shared/utils/debounce_search";

export default class extends Controller {
  static targets = [
    "searchForm",
  ];

  onSearchChange() {
    this.timeout = debounceSearch(this.timeout, this.submitSearchForm.bind(this));
  }

  submitSearchForm() {
    this.searchFormTarget.requestSubmit();
  }
}
