import {Controller} from "@hotwired/stimulus";
import parse from "../shared/utils/markdownIt";

export default class extends Controller {
  static targets = ["output", "input", "preview"];
  static values = {content: String};

  connect() {
    if (this.hasContentValue) {
      // Static markdown rendering (for display)
      this.outputTarget.innerHTML = parse(this.contentValue);
    } else if (this.hasInputTarget) {
      // Live preview for form editing
      this.preview();
    }
  }

  preview() {
    if (!this.hasPreviewTarget) return;
    const markdown = this.inputTarget.value;
    this.previewTarget.innerHTML = parse(markdown);
  }
}
