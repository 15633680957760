import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

// Connects to data-controller="safe-assign"
export default class extends Controller {
  static values = {
    title: String,
  };

  connect() {
    if (window.location.pathname.match(/\/submissions/)) {
      this.getSafeAssigns();
    }
  }

  async getSafeAssigns() {
    let submission_id = window.location.pathname.match(/\/submissions\/(\d*)\/review/)[1];
    const response = await get(`/safe_assigns/${submission_id}`, {
      query: {title_of_question: this.titleValue},
      responseKind: "html",
    });
    if (response.ok) {
      const html = await response.html;
      this.element.innerHTML = html;
    }
  }
}
