import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="announcement"
export default class extends Controller {
  static values = {cookie: String};

  hide() {
    this._createCookie(this.cookieValue, "hidden", 365);
    this.element.classList.add("hidden");
  }

  _createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }
}
