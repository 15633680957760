import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "dropdown"];
  domains = ["gmail.com", "hotmail.com", "icloud.com", "yahoo.com", "live.com", "outlook.com"];

  suggest() {
    const value = this.inputTarget.value;
    const atPosition = value.indexOf("@");

    if (atPosition !== -1) {
      const enteredDomain = value.slice(atPosition + 1).toLowerCase();
      this.showSuggestions(enteredDomain);
    } else {
      this.dropdownTarget.classList.add("hidden");
    }
  }

  showSuggestions(enteredDomain) {
    const filteredDomains = this.domains.filter((domain) => domain.startsWith(enteredDomain));

    if (filteredDomains.length > 0) {
      this.dropdownTarget.innerHTML = filteredDomains
        .map(
          (domain) =>
            `<div class="p-2 cursor-pointer hover:bg-gray-200" data-action="click->email-autocomplete#select">${domain}</div>`
        )
        .join("");
      this.dropdownTarget.classList.remove("hidden");
    } else {
      this.dropdownTarget.classList.add("hidden");
    }
  }

  select(event) {
    const selectedDomain = event.target.textContent;
    const value = this.inputTarget.value;
    const atPosition = value.indexOf("@");
    this.inputTarget.value = `${value.slice(0, atPosition + 1)}${selectedDomain}`;
    this.dropdownTarget.classList.add("hidden");
  }
}
