import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="locale"
export default class extends Controller {

  change(e) {
    e.preventDefault();
    const lang = e.target.dataset.value;
    const currentUrl = window.location.href;

    if (lang === "en") {
      const newUrl = this.#changeLanguageToEnglish(currentUrl);
      window.location.href = newUrl;
    } else {
      const newUrl = this.#changeLanguageToArabic(currentUrl);
      window.location.href = newUrl;
    }
  }

  #changeLanguageToEnglish(currentUrl) {
      const parts = currentUrl.split("/");
      parts.splice(3, 0, "en");
      return parts.join("/");
  }

  #changeLanguageToArabic(currentUrl) {
    return currentUrl.replace("/en", "");
  }

}
