import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="nelc"
export default class extends Controller {
  static targets = ["inputField", "copy"];

  static values = {
    source: String,
  };

  showKey() {
    if (this.inputFieldTarget.type == "text") {
      this.inputFieldTarget.type = "password";
    } else {
      this.inputFieldTarget.type = "text";
      this.replace_show_btn_with_copy();
    }
  }

  replace_show_btn_with_copy() {
    this.copyTarget.classList.remove("hidden");
  }

  copy(e) {
    navigator.clipboard.writeText(this.sourceValue);
  }
}
