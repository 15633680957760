import {Controller} from "@hotwired/stimulus";
import { post } from "@rails/request.js"

export default class extends Controller {
  initialize() {
    this.initializeElementsSizes = {};
    this.#affectedElements().forEach((element) => {
      this.initializeElementsSizes[this.#getXPath(element)] = parseInt(
        window.getComputedStyle(element).fontSize
      );
    });
    setTimeout(
      function () {
        this.loadChangeAmount();
      }.bind(this),
      1000
    );
  }

  increase(e) {
    this.saveChangeAmount(1);
    const savedAmount = localStorage.getItem("fontSizeChangeAmount") || 0;
    this.changeFontSize(parseInt(savedAmount), this.#affectedElements());
    e.stopPropagation();
  }

  decrease(e) {
    this.saveChangeAmount(-1);
    const savedAmount = localStorage.getItem("fontSizeChangeAmount") || 0;
    this.changeFontSize(parseInt(savedAmount), this.#affectedElements());
    e.stopPropagation();
  }

  darkMode(e) {
    let theme = localStorage.getItem("themePreference");
    if (theme === "light" || theme == null) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("themePreference", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("themePreference", "light");
    }
    e.stopPropagation();
  }

  changeFontSize(changeAmount, affectedElements) {
    const initializeElementsSizes = this.initializeElementsSizes;
    affectedElements.forEach((element) => {
      const currentSize = initializeElementsSizes[this.#getXPath(element)];
      element.style.fontSize = `${currentSize + changeAmount}px`;
    });
  }

  saveChangeAmount(changeAmount) {
    const savedAmount = localStorage.getItem("fontSizeChangeAmount") || 0;
    let newAmount = 0;
    if (savedAmount) {
      newAmount += parseInt(savedAmount);
    }

    newAmount += changeAmount;
    localStorage.setItem("fontSizeChangeAmount", newAmount.toString());
  }

  loadChangeAmount() {
    const savedAmount = localStorage.getItem("fontSizeChangeAmount") || 0;
    const affectedElements = this.#affectedElements();
    this.changeFontSize(parseInt(savedAmount), affectedElements);
  }

  async toggleLanguage(e) {
    let currentLocale = document.documentElement.lang;
    if (currentLocale === "en") {
      await post("/accessibility/change_language?locale=ar");
      window.location.reload();
    } else {
      await post("/accessibility/change_language?locale=en");
      window.location.reload();
    }
    e.stopPropagation();
  }

  #affectedElements() {
    return document.querySelectorAll(
      "p:not(.font_size), button:not(.font_size), summary:not(.font_size), " +
        "details:not(.font_size), h1, h2, h3, h4, h5, h6, a, span:not(.font_size), strong, " +
        "input, div"
    );
  }

  #getXPath(element) {
    let xpath = "";
    for (; element && element.nodeType == Node.ELEMENT_NODE; element = element.parentNode) {
      let tagName = element.tagName.toLowerCase();
      let index = 0;
      for (let sibling = element.previousSibling; sibling; sibling = sibling.previousSibling) {
        if (sibling.nodeType == Node.ELEMENT_NODE && sibling.tagName.toLowerCase() == tagName) {
          index++;
        }
      }
      xpath = "/" + tagName + "[" + (index + 1) + "]" + xpath;
    }
    return xpath;
  }
}
