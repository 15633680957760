import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = ["yearSelect", "monthSelect", "allSales"];

  toggleSelects(event) {
    const disable = event.target.checked;
    this.yearSelectTarget.disabled = disable;
    this.monthSelectTarget.disabled = disable;
  }

  updateMonths() {
    const year = this.yearSelectTarget.value;
    get(`/school/sales_reports/available_months?year=${year}`, {
      responseKind: "json",
    })
      .then((response) => response.json)
      .then((data) => {
        this.monthSelectTarget.innerHTML = "";
        data.months.forEach((month) => {
          const option = document.createElement("option");
          option.value = month;
          option.textContent = month;
          this.monthSelectTarget.appendChild(option);
        });
        this.monthSelectTarget.disabled = false;
      });
  }
}
