import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputField"];
  showKey() {
    if (this.inputFieldTarget.type == "text") {
      this.inputFieldTarget.type = "password";
    } else {
      this.inputFieldTarget.type = "text";
    }
  }
}
