import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";

// Connects to data-controller="payment"
export default class extends Controller {
  static targets = ["cardsForm"];
  static values = {
    locale: String,
    sessionId: String,
    countryCode: String,
    amount: String,
  };

  connect() {
    this.waitForMyFatoorah();
  }

  waitForMyFatoorah() {
    if (typeof myFatoorah !== "undefined") {
      this.initializeMyFatoorah();
    } else {
      setTimeout(() => this.waitForMyFatoorah(), 50);
    }
  }

  initializeMyFatoorah() {
    var formConfig = {
      countryCode: this.countryCodeValue,
      sessionId: this.sessionIdValue,
      lang: this.localeValue,
      cardViewId: "card-element",
      style: {
        cardHeight: 165,
        direction: this.localeValue === "ar" ? "rtl" : "ltr",
        input: {
          fontSize: "0.9rem",
          inputHeight: "35px",
          inputMargin: "8px",
          borderColor: "#d1d5db",
          borderRadius: "0.375rem",
          boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
          placeHolder: {
            holderName: i18n.t("shared.creditcard.name"),
            cardNumber: i18n.t("shared.creditcard.number"),
          },
        },
        error: {
          borderColor: "rgba(253, 164, 175, 1)",
          boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        },
      },
    };

    myFatoorah.init(formConfig);
  }

  submit(e) {
    let elem = e.target;
    elem.disabled = true;
    myFatoorah.submit().then(
      (response) => {
        this.cardsFormTarget.querySelector("#session_id").value = response.sessionId;
        this.cardsFormTarget.requestSubmit();
      },
      function (err) {
        elem.disabled = false;
        console.error(err);
        // No need to sent this err to error monitorting service
        if (err != "Card details are invalid or missing!") {
          if (typeof Rollbar !== "undefined") {
            Rollbar.critical(err);
          }
        }
      }
    );
  }
}
