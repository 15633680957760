import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";
import "../shared/styles/home.css";

export default class extends Controller {
  static outlets = ["slideover"]
  static values = {
    id: Number,
    show: Boolean,
    tour: String,
  };

  connect() {
    this.#startTour();
  }

  startAcademyTourManually() {
    this.slideoverOutlet.close();
    this.#startAcademyTour();
  }

  #startAcademyTour() {
    this.tourValue = "academy";
    this.#startTour(true);
  }

  #startTour(manual = false) {
    if (!this.showValue) {
      return;
    }

    if (JSON.parse(localStorage.getItem(`${this.tourValue}_tour`))?.completed && !manual) {
      return;
    }

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: true,
      },
    });

    const home_steps = [
      {
        title: i18n.t("shared.tours.home.step1.title"),
        text: i18n.t("shared.tours.home.step1.text"),
        attachTo: {
          element: ".school-index__about",
          on: "bottom",
        },
        classes: "shadow bg-white rounded-lg p-5",
        buttons: [
          {
            text: i18n.t("shared.tours.home.step1.later"),
            classes: "btn btn-primary-ghost mt-5 ring-1 ring-primary-100",
            action: tour.cancel,
          },
          {
            text: i18n.t("shared.tours.home.step1.start"),
            classes: "btn btn-primary mt-5",
            action: tour.next,
          },
          {
            text: i18n.t("shared.tours.home.step1.end"),
            classes: "btn btn-danger mt-5",
            action: tour.complete,
          },
        ],
      },
      this.#createTourStep(tour, this.tourValue, 6, "#academy_name", "top"),
      this.#createTourStep(tour, this.tourValue, 5, "#person_profile"),
      this.#createTourStep(tour, this.tourValue, 2, ".student-navbar__links-container"),
      this.#createTourStep(tour, this.tourValue, 3, "#academy_logo"),
      {
        title: i18n.t("shared.tours.home.step4.title"),
        text: i18n.t("shared.tours.home.step4.text"),
        attachTo: {
          element: ".footer__container",
          on: "top",
        },
        classes: "shadow bg-white rounded-lg p-5",
        buttons: [
          {
            text: i18n.t("shared.tours.home.step1.back"),
            classes: "btn btn-primary mt-5",
            action: tour.back,
          },
          {
            text: i18n.t("shared.tours.home.step4.complete"),
            classes: "btn btn-primary mt-5",
            action: tour.next,
          },
        ],
      },
    ];

    const academy_steps = [
      {
        title: i18n.t("shared.tours.academy.step1.title"),
        text: i18n.t("shared.tours.academy.step1.text"),
        attachTo: {
          element: "#school_nav",
          on: "right",
        },
        classes: "shadow bg-white rounded-lg p-5",
        buttons: [
          {
            text: i18n.t("shared.tours.home.step1.later"),
            classes: "btn btn-primary-ghost mt-5 ring-1 ring-primary-100",
            action: tour.cancel,
          },
          {
            text: i18n.t("shared.tours.home.step1.start"),
            classes: "btn btn-primary mt-5",
            action: tour.next,
          },
          {
            text: i18n.t("shared.tours.home.step1.end"),
            classes: "btn btn-danger mt-5",
            action: tour.complete,
          },
        ],
      },
      this.#createTourStep(tour, this.tourValue, 2, "#MainNavcourses"),
      this.#createTourStep(tour, this.tourValue, 3, "#MainNavvcs"),
      this.#createTourStep(tour, this.tourValue, 4, "#MainNavsubscriptions"),
      this.#createTourStep(tour, this.tourValue, 5, "#MainNavproducts"),
      this.#createTourStep(tour, this.tourValue, 6, "#MainNavbooks"),
      this.#createTourStep(tour, this.tourValue, 7, "#MainNavpages"),
      this.#createTourStep(tour, this.tourValue, 8, "#MainNavblog_posts"),
      this.#createTourStep(tour, this.tourValue, 9, "#MainNavcoaches"),
      this.#createTourStep(tour, this.tourValue, 10, "#MainNavcommunities", "top"),
      this.#createTourStep(tour, this.tourValue, 11, "#MainNavsettings", "top"),
      this.#createTourStep(tour, this.tourValue, 12, "#MainNavreports", "top"),
      this.#createTourStep(tour, this.tourValue, 13, "#MainNavsupport", "top"),
      {
        title: i18n.t("shared.tours.academy.step14.title"),
        text: i18n.t("shared.tours.academy.step14.text"),
        attachTo: {
          element: "#courses_list",
          on: "top",
        },
        classes: "shadow bg-white rounded-lg p-5",
        buttons: [
          {
            text: i18n.t("shared.tours.home.step1.back"),
            classes: "btn btn-primary mt-5",
            action: tour.back,
          },
          {
            text: i18n.t("shared.tours.home.step4.complete"),
            classes: "btn btn-primary mt-5",
            action: tour.next,
          },
        ],
      },
    ];

    if (this.tourValue == "home") {
      if (window.innerWidth < 768) {
        document.addEventListener('DOMContentLoaded', () => {
          document.querySelector(".student-navbar__menu-btn").click();
        });
        home_steps.splice(2, 1);
      }
      tour.addSteps(home_steps);
    } else if (this.tourValue == "academy") {
      tour.addSteps(academy_steps);
    }

    document.addEventListener('DOMContentLoaded', () => {
      tour.start();
    });

    const tourData = { user_id: this.idValue, completed: true };
    tour.on("start", () => {
      const courseSearch = document.querySelector(".course_search")
      if (courseSearch) {
        courseSearch.classList.remove('z-30');
      }
    });

    ['cancel', 'complete'].forEach(event => tour.on(event, () => {
      const courseSearch = document.querySelector(".course_search")
      if (courseSearch) {
        courseSearch.classList.add('z-30');
      }
    }));

    tour.on("complete", () => {
      localStorage.setItem(`${this.tourValue}_tour`, JSON.stringify(tourData));
    });
  }

  #createTourStep(tour, tourName, step, selector, position = "bottom") {
    return {
      title: i18n.t(`shared.tours.${tourName}.step${step}.title`),
      text: i18n.t(`shared.tours.${tourName}.step${step}.text`),
      attachTo: {
        element: selector,
        on: position,
      },
      classes: "shadow bg-white rounded-lg p-5",
      buttons: [
        {
          text: i18n.t("shared.tours.home.step1.back"),
          classes: "btn btn-primary mt-5",
          action: tour.back,
        },
        {
          text: i18n.t("shared.tours.home.step1.next"),
          classes: "btn btn-primary mt-5",
          action: tour.next,
        },
      ],
    };
  }
}
