import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
export default class extends Controller {
  static values = {
    item: Object,
    user: Object,
    key: String,
    urls: Object,
    token: String,
  };

  connect() {
    const gateway = {
      publicKey: this.keyValue,
      language: this.userValue.locale,
      supportedCurrencies: ["SAR"],
      supportedPaymentMethods: "all",
      saveCardOption: false,
      callback: (res) => {
        if (res.callback.errors.length > 0) {
          const code = res.callback.errors[0].code
          if (code > 2000 && code != 2118){
            Rollbar.critical(res.callback.errors);
          }
        }
      },
      onClose: () => {
        window.location = document.referrer;
      },
      backgroundImg: {
        opacity: "0.5",
      },
      labels: {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Name on Card",
        actionButton: i18n.t("shared.tap.pay"),
      },
      style: {
        base: {
          color: "#6F26AB",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.26)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
          iconColor: "#fa755a ",
        },
      },
    };

    const customer = {
      first_name: this.userValue.name,
      middle_name: "",
      last_name: "",
      email: this.userValue.email,
      phone: {
        country_code: "",
        number: this.userValue.phone,
      },
    };

    const order = {
      amount: this.itemValue.price,
      currency: "SAR",
      items: [
        {
          id: this.itemValue.id,
          name: this.itemValue.name,
          description: this.itemValue.description,
          quantity: "1",
          amount_per_unit: this.itemValue.price,
          total_amount: this.itemValue.price,
        },
      ]
    };

    const transaction = {
      mode: "charge",
      charge: {
        saveCard: false,
        threeDSecure: true,
        description: i18n.t("shared.tap.desc"),
        statement_descriptor: "Sample",
        reference: {
          transaction: "",
          order: "",
        },
        metadata: {},
        receipt: {
          email: true,
          sms: false,
        },
        redirect: window.location.href,
        post: null,
      },
    };

    if (window.location.search.includes("token")) {
      goSell.showResult(this.#getResult(this.urlsValue, this.tokenValue));
      return;
    }

    goSell.config(this.#getConfig(gateway, customer, order, transaction));
    goSell.openLightBox();
  }

  #getConfig(gateway, customer, order, transaction) {
    return {
      containerID: "root",
      gateway: gateway,
      customer: customer,
      order: order,
      transaction: transaction,
    };
  }

  #getResult(urls, token) {
    return {
      callback: (res) => {
        const transID = res.callback.id;
        try {
          fetch(`${window.location.protocol}//${window.location.host}${urls.post}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": document
                .querySelector("meta[name='csrf-token']")
                .getAttribute("content"),
            },
            body: JSON.stringify({transID, token}),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status == "success") {
                location.href = `${window.location.protocol}//${window.location.host}${data.redirect}`;
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
    };
  }
}
