import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab"];

  // https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/tabs.js
  connect() {
    // Sync with URL on page load
    const url = new URL(window.location);
    const resource = url.searchParams.get("resource");

    if (resource) {
      // Find index of tab with matching resource
      const tabIndex = this.tabTargets.findIndex(tab => {
        const href = new URL(tab.href);
        return href.searchParams.get("resource") === resource;
      });
      if (tabIndex >= 0) {
        this.index = tabIndex;
      }
    }
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);

    // Get resource from clicked tab's href
    const clickedUrl = new URL(event.currentTarget.href);
    const resource = clickedUrl.searchParams.get("resource");

    // Update URL with resource parameter
    const url = new URL(window.location);
    url.searchParams.set("resource", resource);
    window.history.pushState({}, "", url);
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index === this.index) {
        tab.classList.remove(
          "border-transparent",
          "text-gray-500",
          "hover:text-gray-700",
          "hover:border-gray-300"
        );
        tab.classList.add("border-primary-500", "text-primary-600");
      } else {
        tab.classList.remove("border-primary-500", "text-primary-600");
        tab.classList.add(
          "border-transparent",
          "text-gray-500",
          "hover:text-gray-700",
          "hover:border-gray-300"
        );
      }
    });
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value >= 0 ? value : 0);
    this.showTab();
  }
}
