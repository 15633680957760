import {Controller} from "@hotwired/stimulus";
import {post} from "@rails/request.js";
import consumer from "../channels/consumer";
import Notify from "../shared/notifier";
import i18n from "~/shared/i18n.js";

export default class extends Controller {
  static values = {room: String, user: String, name: String};
  join_attempts = 0;
  min = 5;
  max = 15;

  initialize() {
    consumer.subscriptions.create(
      {
        channel: "WaitingChannel",
        roomuid: this.roomValue,
        useruid: this.userValue,
      },
      {
        // Called once when the subscription is created.
        initialized() {
          console.log("initialized");
        },

        // Called when the subscription is ready for use on the server.
        connected() {
          console.log("connected");
        },

        // Called when the WebSocket connection is closed.
        disconnected(data) {
          console.log("disconnected");
          console.log(data);
        },

        // Called when the subscription is rejected by the server.
        rejected() {
          console.log("rejected");
        },

        received: (data) => {
          console.log(data);
          let randomNumber =
            Math.floor(Math.random() * (this.max - this.min + 1) + this.min) * 1000;
          if (data.action == "started") {
            setTimeout(() => {
              this.request_to_join_meeting(this.nameValue);
            }, randomNumber);
          }
        },
      }
    );
  }

  async request_to_join_meeting(name) {
    const response = await post(`${window.location.pathname}/?join_name=${name}`, {
      responseKind: "json",
    });
    if (response.statusCode === 302) {
      const body = await response.json;
      window.location.replace(body.url);
    } else if (response.statusCode === 203) {
      if (this.join_attempts < 4) {
        setTimeout(() => {
          this.request_to_join_meeting(name);
        }, 120000);
      }
      Notify(null, i18n.t('shared.full'), 'notice');
    } else {
      if (this.join_attempts < 4) {
        setTimeout(() => {
          this.request_to_join_meeting(name);
        }, 1000);
      }
      this.join_attempts++;
    }
  }
}
