import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ["content"];

  mouseOut() {
    this.contentTarget.classList.add("hidden");
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.contentTarget.classList.contains("hidden")) {
      this.contentTarget.classList.remove("hidden");
    } else {
      this.contentTarget.classList.add("hidden");
    }
  }
}
