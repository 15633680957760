import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["query", "results"];
  static values = {url: String};

  disconnect() {
    this._reset();
  }

  fetchResults() {
    if (this.query == "") {
      this._reset();
      return;
    }

    if (this.query == this.previousQuery) {
      return;
    }
    this.previousQuery = this.query;

    this.abortPreviousFetchRequest();

    this.abortController = new AbortController();
    fetch(`${this.urlValue}?query=${this.query}`, {signal: this.abortController.signal})
      .then((response) => response.text())
      .then((html) => {
        this.resultsTarget.innerHTML = html;
      })
      .catch(() => {});
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event);
    }
  }

  _reset() {
    this.resultsTarget.innerHTML = "";
    this.queryTarget.value = "";
    this.previousQuery = null;
  }

  abortPreviousFetchRequest() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  get query() {
    return this.queryTarget.value;
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.resultsTarget.firstElementChild,
      "search-results"
    );
  }
}
