import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["url", "code", "moderatorCode", "menu", "searchForm", "dialog"];
  static values = {uid: String};

  copyInvite() {
    this.urlTarget.select();
    navigator.clipboard.writeText(this.urlTarget.value);
  }

  copyAccess() {
    navigator.clipboard.writeText(this.codeTarget.value);
  }

  copyModeratorAccess() {
    navigator.clipboard.writeText(this.moderatorCodeTarget.value);
  }

  openDropDown(e) {
    e.stopPropagation();
    this.menuTarget.classList.remove("hidden", "invisible", "transform", "opacity-0", "scale-95");
  }

  closeDropDown() {
    this.menuTarget.classList.add("hidden", "invisible", "transform", "opacity-0", "scale-95");
  }

  openDialog() {
    this.dialogTarget.showModal();
  }

  closeDialog() {
    this.dialogTarget.close();
  }

  onSearchChange() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.#submitSearchForm();
    }, 300);
  }

  #submitSearchForm() {
    this.searchFormTarget.requestSubmit();
  }
}
