import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    studentId: String
  }

  connect() {
    this.loadAttendanceData()
  }

  async loadAttendanceData() {
    const response = await get(`/school/students/${this.studentIdValue}/attendances`)
    if (response.ok) {
      const html = await response.html
      this.element.innerHTML = html
    }
  }
}
