import {Controller} from "@hotwired/stimulus";
import Notify from "../shared/notifier";
import i18n from "~/shared/i18n.js";

export default class extends Controller {
  static values = {key: String, message: String};

  connect() {
    let heading;
    switch (this.keyValue) {
      case "success":
        this.keyValue = "success";
        heading = i18n.t("shared.notifications.success");
        break;
      case "error":
        this.keyValue = "error";
        heading = i18n.t("shared.notifications.error");
        break;
      default:
        this.keyValue = "notice";
        heading = i18n.t("shared.notifications.notice");
        break;
    }
    Notify(heading, this.messageValue, this.keyValue);
  }
}
