import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["colorItem", "extraItems", "checkboxItem"];

  connect() {
    if (this.checkboxItemTarget.checked) {
      this.extraItemsTarget.classList.remove("hidden");
      this.colorItemTarget.classList.add("hidden");
    }
  }

  toggleExtraItems() {
    this.extraItemsTarget.classList.toggle("hidden");
    this.colorItemTarget.classList.toggle("hidden");
  }
}
