import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="slideover"
export default class extends Controller {
  static targets = ["container"];

  close() {
    this.containerTarget.classList.add("hidden");
    this.element.querySelector('#slideover_content').innerHTML = `
      <div class="flex justify-center items-center p-6">
        <i class="fas fa-spinner fa-spin me-2"></i>
      </div>
    `;
  }

  open() {
    this.containerTarget.classList.remove("hidden");
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }
}
