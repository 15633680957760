import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["content", "icon", "subicon"];

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.subiconTarget.classList.toggle("hidden");
    this.iconTarget.classList.toggle("hidden");
    this.subiconTarget.classList.toggle("block");
  }
}
