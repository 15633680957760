import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="whatsapp"
export default class extends Controller {
  static targets = ["chatBox"];

  open() {
    this.chatBoxTarget.classList.remove("hidden");
  }

  close() {
    this.chatBoxTarget.classList.add("hidden");
  }
}
