import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {
    source: String,
  };

  copy(e) {
    navigator.clipboard.writeText(this.sourceValue);
  }
}
