import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = ["monthlyBox", "annuallyBox", "plan", "storage", "sessions", "lmsAddon"];

  static values = {
    plan: String,
    storage: String,
    sessions: String,
    lmsAddon: String,
    locale: String,
  };

  initialize() {
    this.months = 1;
  }

  toggleBilling(event) {
    const toggleSpan = event.currentTarget.querySelector('span[aria-hidden="true"]');
    const isRTL = document.documentElement.dir === "rtl";
    if (this.months === 12) {
      this.months = 1;
      event.currentTarget.classList.add("bg-indigo-600");
      event.currentTarget.classList.remove("bg-primary-500");
      event.currentTarget.setAttribute("aria-checked", "false");
      toggleSpan.classList.remove(isRTL ? "translate-x-[-1.25rem]" : "translate-x-5");
      toggleSpan.classList.add("translate-x-0");
    } else {
      this.months = 12;
      event.currentTarget.classList.add("bg-primary-500");
      event.currentTarget.classList.remove("bg-indigo-600");
      event.currentTarget.setAttribute("aria-checked", "true");
      toggleSpan.classList.remove("translate-x-0");
      toggleSpan.classList.add(isRTL ? "translate-x-[-1.25rem]" : "translate-x-5");
    }
    this._fetchForm();
    if (this.hasPlanTarget) {
      this.planValue = this.planTarget.options[this.planTarget.selectedIndex].value;
    }
    if (this.hasStorageTarget) {
      this.storageValue = this.storageTarget.options[this.storageTarget.selectedIndex].value;
    }
    if (this.hasSessionsTarget) {
      this.sessionsValue = this.sessionsTarget.options[this.sessionsTarget.selectedIndex].value;
    }
    if (this.hasLmsAddonTarget) {
      this.lmsAddonValue = this.lmsAddonTarget.options[this.lmsAddonTarget.selectedIndex].value;
    }
  }

  updateStorage(e) {
    this.storageValue = e.target.options[e.target.selectedIndex].value;
    this._fetchForm();
  }

  updateSessions(e) {
    this.sessionsValue = e.target.options[e.target.selectedIndex].value;
    this._fetchForm();
  }

  updateLmsAddon(e) {
    this.lmsAddonValue = e.target.options[e.target.selectedIndex].value;
    this._fetchForm();
  }

  submit() {
    this.element.submit();
  }

  _fetchForm(e) {
    this.#showLoadingIndicator();
    const params = new URLSearchParams();
    params.append("payment[plan]", this.planValue);
    params.append("payment[storage]", this.storageValue);
    params.append("payment[sessions]", this.sessionsValue);
    params.append("payment[months]", this.months);
    params.append("payment[lms_addon]", this.lmsAddonValue);
    params.append("locale", this.localeValue);
    get(`/payments/new?${params.toString()}`, {
      responseKind: "turbo-stream",
    });
  }

  #showLoadingIndicator() {
    document.querySelector("#payment_form").innerHTML = `
       <div class="flex justify-center items-center p-6">
         <i class="fas fa-spinner fa-spin me-2"></i>
       </div>
     `;
  }
}
