import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectedStudents"];
  static values = {selected: Array};

  connect() {
    this.input = this.element.querySelector("input");
    this.optionsList = this.element.querySelector(".options-list");
    this.selectedOptions = [];

    this.input.addEventListener("input", this.search.bind(this));
    this.optionsList.addEventListener("click", this.toggleOption.bind(this));
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  search() {
    const searchTerm = this.input.value.toLowerCase();
    const options = this.optionsList.querySelectorAll(".option");

    options.forEach((option) => {
      const text = option.innerText.toLowerCase();
      option.style.display = text.includes(searchTerm) ? "block" : "none";
    });
  }

  toggleOption(event) {
    const option = event.target.closest(".option");
    if (option) {
      const value = option.dataset.value;

      if (this.selectedOptions.includes(value)) {
        this.selectedOptions = this.selectedOptions.filter((v) => v !== value);
      } else {
        this.selectedOptions.push(value);
      }
      this.toggleCheckmark(value, option);

      this.selectedValue = this.selectedOptions;
      this.selectedStudentsTarget.value = this.selectedValue;
    }
  }

  toggleCheckmark(value, option) {
    const checkmark = option.querySelector(".checkmark");

    if (this.selectedOptions.includes(value)) {
      checkmark.classList.remove("hidden");
    } else {
      checkmark.classList.add("hidden");
    }
  }

  handleKeyDown(event) {
    if (event.key === "a" && event.ctrlKey) {
      event.preventDefault();

      const allOptions = Array.from(this.optionsList.querySelectorAll(".option"));
      const selectedValues = this.selectedOptions.map(String);

      if (selectedValues.length === allOptions.length) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions = allOptions.map((option) => option.dataset.value);
      }

      this.selectedValue = this.selectedOptions;
      this.selectedStudentsTarget.value = this.selectedValue;
      allOptions.forEach((option) => this.toggleCheckmark(option.dataset.value, option));
    }
  }
}
